// 考试记录
// 专项考试列表
<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.push('/web/specialTestList')"
            >专项考试</a
          >
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">考试记录</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input
                v-model="userName"
                type="text"
                size="small"
                clearable
                placeholder="请输入姓名"
              />
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input
                v-model="idcard"
                type="text"
                size="small"
                clearable
                placeholder="请输入身份证号"
              />
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input
                v-model="mobile"
                type="text"
                size="small"
                clearable
                placeholder="请输入手机号"
              />
            </div>
          </div>
          <div class="searchbox">
            <div title="考试结果" class="searchboxItem ci-full">
              <span class="itemLabel">考试结果:</span>
              <el-select
                clearable
                size="small"
                v-model="examState"
                placeholder="请选择考试结果"
              >
                <el-option label="合格" value="20"></el-option>
                <el-option label="不合格" value="30"></el-option>
              </el-select>
            </div>
            <div title="交卷形式" class="searchboxItem ci-full">
              <span class="itemLabel">交卷形式:</span>
              <el-select
                clearable
                size="small"
                v-model="submitWay"
                placeholder="请选择交卷形式"
              >
                <el-option label="自主交卷" value="10"></el-option>
                <el-option label="强制交卷" value="20"></el-option>
              </el-select>
            </div>
            <div class="btnBox" style="margin-left: 20px">
              <el-button
                style="margin-left: 20px"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
              <el-button class="bgc-bv" round @click="doExport()"
                >导出</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="姓名"
                align="left"
                prop="userName"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.userName || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="身份证号"
                align="left"
                prop="idcard"
                show-overflow-tooltip
                min-width="150"
              >
                <template slot-scope="scope">
                  {{ scope.row.idcard || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="手机号"
                align="left"
                prop="mobile"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.mobile || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="进入考试时间"
                align="left"
                prop="startTime"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="交卷时间"
                align="center"
                prop="commitTime"
                show-overflow-tooltip
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                label="考试用时"
                align="left"
                prop="wasteTime"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <!-- {{ getTime(scope.row.wasteTime) }} -->
                  {{scope.row.wasteTimeString}}
                </template>
              </el-table-column>
              <el-table-column
                label="考试分数"
                align="left"
                prop="examScore"
                show-overflow-tooltip
              />
              <el-table-column
                label="考试结果"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  $setDictionary("EXAM_PAPER_STATE", scope.row.examState)
                }}</template>
              </el-table-column>
              <el-table-column
                label="交卷形式"
                align="left"
                show-overflow-tooltip
                min-width="100"
                ><template slot-scope="scope">{{
                  $setDictionary("SUBMIT_WAY", scope.row.submitWay)
                }}</template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="100px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="lookDetail(scope.row.detailsId)"
                    >查看试卷</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "specialTestRecordList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      submitWay: "",
      examState: "",
      mobile: "",
      userName: "",
      idcard: "",
    };
  },
  computed: {},
  created() {
    this.getTableHeight();
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        examId: this.$route.query.examId,
        mobile: this.mobile,
        userName: this.userName,
        idcard: this.idcard,
        examState: this.examState,
        submitWay: this.submitWay,
      };

      if (this.trainTypeId) {
        params.trainTypeId = this.trainTypeId;
      }

      this.doFetch({
        url: "/biz/exam/user/details/pageList",
        params,
        pageNum,
      });
    },
    doExport() {
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        examId: this.$route.query.examId,
        mobile: this.mobile,
        userName: this.userName,
        idcard: this.idcard,
        examState: this.examState,
        submitWay: this.submitWay,
      };
      this.$post("/biz/exam/user/details/listExport", params).then((res) => {
        if (res.status == "0") {
          window.open(res.data);
        }
      });
    },
    lookDetail(id) {
      this.$router.push({
        path: "/web/specialTestRecordDetail",
        query: {
          id,
          toStu: "1",
        },
      });
    },
    //
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    getTime(val) {
      if (!val) {
        return "--";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {},
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less">
</style>

